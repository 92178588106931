@import "./../../color.scss";

.category_block {
  .max_h_catgw {
    max-height: 350px;
    overflow-x: hidden;
  }
  .max_h_c {
    max-height: calc(100vh - 245px);
    overflow-x: hidden;
  }
  .app_url {
    margin-right: 10px;
    margin-top: 10px;
  }
  .categorylist {
    padding: 10px 15px;
    border-bottom: 1px solid $border-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      border: 0;
    }
    &:hover {
      background-color: $sidebar-bg;
    }
    .app_i {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 25px;
        font-size: 20px;
        color: $text-color;
      }
      img {
        margin-right: 10px;
      }
    }
    .c_text {
      text-transform: capitalize;
    }
    .c_flex_d {
      flex-direction: column;
      display: flex;
    }

    .c_txt {
      font-size: 0.75rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 5px;
      opacity: 0.7;
      svg {
        margin-right: 5px;
      }
    }
    .ct_total {
      font-size: 0.8rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .sp_t {
        font-weight: 800;
        color: $text-hovercolor;
        font-size: 0.9rem;
      }
      svg {
        margin-right: 5px;
        color: $text-hovercolor;
      }
    }
  }

  .charts {
    border: 0;
    border-radius: 0;
    margin-top: 1px;
    &:first-child {
      border-right: 1px solid $border-color;
    }
  }
  .search {
    padding-left: 10px;
  }
  .ant-card-body {
    padding: 0;
  }
  .active {
    background-color: $primaryopi;
  }

  .ant-table-content {
    tr {
      th {
        border-radius: 0 !important;
      }
    }
    .ant-progress {
      margin: 0;
      .ant-progress-bg {
        height: 10px !important;
      }
    }
  }
}

.appmodal {
  .ant-drawer-body {
    padding: 0;
  }
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    padding: 15px 15px 0;
    text-transform: capitalize;
  }
  .ant-tabs-nav-wrap {
    padding: 0 15px;
  }
  // .ant-tabs-content{ max-height: 300px; min-height: 300px; overflow-x: hidden;}
  .ant-tabs-nav {
    margin-bottom: 0px;
  }
}
.border0 {
  border: 0px;
  border-radius: 0;
}


.badeg {
  padding: 2px 15px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 0.75rem;
  font-weight: 600;
  position: relative;
  &.productive {
    background-color: $sucess-bg;
    color: $white-bg;
    &::before {
      content: "productive";
    }
  }
  &.unproductive {
    background-color: $danger-bg;
    color: $white-bg;
    &::before {
      content: "unproductive";
    }
  }
  &.nutral {
    background-color: $grey_bg;
    color: $white-bg;
    &::before {
      content: "nutral";
    }
  }
}

.app_charts {
  .legend_left {
    .apexcharts-legend {
      display: none !important;
    }
  }
}
